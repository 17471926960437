<script setup lang="ts">
const props = defineProps({
	modelValue: {
		type: Boolean,
		required: true,
	},
	title: {
		type: String,
		required: true
	},
	loading: {
		type: Boolean,
		default: false
	},
	fullscreen: {
		type: Boolean,
		default: false
	},
	onConfirm: {
		type: Function,
		required: true
	},
	onClear: {
		type: Function,
		default: undefined
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t } = useI18n({
	useScope: 'local'
})

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const onConfirmLocal = () => {
	props.onConfirm()
	onClose()
}

const onClearLocal = () => {
	if (props.onClear) {
		props.onClear()
	}
	onClose()
}

const onClose = () => {
	localValue.value = false
}
</script>

<template>
	<UModal
		v-model="localValue"
		:fullscreen="fullscreen"
		:ui="{
			container: 'flex sm:items-center justify-center text-center' + (fullscreen ? ' items-stretch' : 'items-end'),
			fullscreen: 'w-screen h-[100svh] flex-1 basis-1',
			rounded: 'rounded-none',
			shadow: 'shadow-none',
			padding: 'p-0 sm:p-0'
		}"
	>
		<div>
			<div class="flex">
				<h3 class="flex-1 px-4 py-3">
					{{ title }}
				</h3>
				<UButton
					variant="ghost"
					color="white"
					size="md"
					icon="i-heroicons-x-mark-16-solid"
					class="w-11 focus:ring-0 focus-visible:ring-0"
					@click="onClose"
				/>
			</div>
			<UDivider />
		</div>
		<div class="flex-1 overflow-y-auto">
			<slot />
		</div>
		<div class="sticky bottom-0 bg-white">
			<UDivider />
			<div class="flex justify-stretch px-2 pt-2 pb-2 gap-2 sticky">
				<UButton
					@click="onConfirmLocal"
					:ui="{
						base: 'shrink'
					}"
					class="py-3"
					:disabled="loading"
					block
				>
					<slot name="button">
						<SearchFilterConfirm />
					</slot>
				</UButton>
				<UButton
					v-if="onClear"
					variant="outline"
					leading-icon="i-heroicons-x-mark-16-solid"
					:ui="{
						base: 'shrink'
					}"
					@click="onClearLocal"
					block
				>
					{{ t('button.clear') }}
				</UButton>
			</div>
			<template v-if="$slots.footer">
				<slot name="footer" />
			</template>
		</div>
	</UModal>
</template>

<i18n lang="json">
{
	"ru": {
		"button": {
			"clear": "Очистить"
		}
	}
}
</i18n>